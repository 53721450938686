import { Box, Button, Container, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import RevelataLogoSvg from './svg/RevelataIncLogo.svg';
import HeroBackgroundPng from './png/hero_background_home.png';
import HeroDeepKpiPng from './png/hero_deepkpi.png';
import HeroDeepAnalysisWebp from './webp/hero_deepanalysis.webp';
import HeroReportsWebp from './webp/hero_reports.webp';
import HeroHomepageSvg from './svg/hero_homepage.svg';
import AlphaIconSvg from './svg/AlphaIcon.svg'

// import { HelloC, HiC } from "revelata-common-ui";

export function Home() {

    function onClickSignUpButton() {
        return null;
    }

    function ResponsiveLede() {
        // const text_headline = <>Become bionic at<br/>investment research & analysis<br/></>
        // const text_subheadline = <>Our AI platform enables you to do company research and<br/>financial modeling better and faster than ever before.</>
        // const text_headline = <>Become bionic at investment research & analysis</>
        // const text_headline = <>Data-driven, understandable investment research & analysis</>
        // const text_headline = <>Data-driven, explainable investment research & analysis</>
        const text_headline = <>Better, faster, data-driven investment research & analysis</>        
        const text_subheadline = <>With nuanced company data found nowhere else, and direct integration into Excel, our AI platform makes you bionic at company research and financial modeling.</>
        
        return (
            <Box
            id="hero"
            sx={{
            //   mt: '-85px',
              p: 0,
            //   width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
                backgroundImage:
                  `url(${HeroBackgroundPng})`,
              }
            }
          >     
                <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', md: '52%' },
                    alignItems: 'center',
                    p: { xs: 2, md: 4 },
                    mt: { xs: 8, md: 14, lg: 20, xl: 24},                       
                    }}
                >  
                    <Stack>
                        <Typography variant="h1" sx={{
                            fontSize: { xs: "30px", md: "40px", lg: "48px", xl: "56px" }, 
                            textAlign: {xs: 'center', md: 'left'},
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30},        
                            }}>
                            {text_headline}
                        </Typography>
                        <Typography variant="h5" fontWeight="400"  sx={{
                            fontSize:  { xs: "14px", md: "18px", lg: "22px" }, 
                            mt: { xs: 3, md: 5 }, 
                            color: "#CBCBCB", 
                            textAlign: {xs: 'center',md: 'left'},
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30},        
                        }}>
                            {text_subheadline}
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: {xs: 'center',md: 'left'} ,  
                            mt: { xs: 4, md: 8 },
                            mb: { xs: 2 },
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30},
        
                        }}>
                            <Button 
                                variant='contained' 
                                sx={{borderRadius: 50, pl: 4, pr: 4, fontWeight: 'bold'}} 
                                size="large" 
                                href="/signup" 
                                onClick={() => onClickSignUpButton("main")}>
                                    Sign Up
                            </Button>
                        {/* <AlphaModal /> */}
                        </Box>
                    </Stack>
                </Box>          
                <Box sx={{
                    mt: {xs: 0, md: 20}, 
                    display: { xs: 'none', md: 'block', lg: 'block' }, 
                    justifyContent: {xs: "center", md: 'flex-end'},
                    alignItems: {xs: "center", md: 'flex-end'},
                    width: { md: '48%' }}}>
                        {/* <HelloC/><HiC/> */}
                    <img
                        src={HeroHomepageSvg} // Replace with the URL or path to your image
                        style={{ width: '100%', objectFit: 'cover'  }}
                        alt="Revelata homepage"
                    />
                </Box>
                <Box sx={{
                    mt: {xs: 0}, 
                    display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' }, 
                    justifyContent: {xs: "center"},
                    alignItems: {xs: "center"},
                    width: { xs: '100%' }}}>
                    <img
                        src={HeroHomepageSvg} // Replace with the URL or path to your image
                        style={{ width: '75%', objectFit: 'cover'  }}
                        alt="Revelata homepage"
                    />
                </Box>
                </Stack>
            </Box> 
        )
    }


    function ResponsiveProductsSection() {

        const onClickLearnMoreButton = (header) => {     
            // ampli.signUpButtonClick({ "Button Location": header});             
            return;
        }

        function ResponsiveSection({section_id, productName, product, text2, text3, list, section_img, productUrl}) {
            return ( 
                <>
                <Stack sx={{ display: {xs: 'flex', md: 'none'}, flexDirection: {xs: 'column'} }}>
                    <Divider/>
                    <Box sx= {{p: 4}}>                                      
                        <Typography variant="h4" sx={{fontSize: { xs: "18px", fontWeight: 600 }, textAlign: {xs: 'left'}}}>
                            {product}
                        </Typography>                            
                        <Typography variant="h5" fontWeight="400"  sx={{fontSize:  { xs: "14px" }, mt: { xs: 1 }, color: "#CBCBCB", textAlign: {xs: 'left'}}}>
                            {text3}
                        </Typography>                            
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>                                      
                        <img                            
                            src={section_img} // Replace with the URL or path to your image
                            style={{width: "90%"}}
                            alt={productName + " screenshot"}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: {xs: 'center'} ,  m: { xs: 2 } }}>
                            <Button variant="outlined" sx={{
                                borderColor: "white",
                                color: "white",
                                textTransform: "none",
                                width: {xs: "medium", md: "150px"}, // Set a fixed width for medium size (adjust if needed)
                                borderRadius: 50,
                                fontWeight: "600",
                                }} href={productUrl} onClick={() => onClickLearnMoreButton(productName)}>
                                Learn More
                            </Button>
                        </Box>

                </Stack>
                <Stack sx={{ display: {xs: 'none', md: 'flex'}, flexDirection: { md: 'row' } }}>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'block'},
                            width: { md: '50%' },                        
                            alignItems: 'stretch',
                            p:  { md: 4 },
                            pl: { md: 10, lg: 10, xl: 20}, 
                            pr: { md: 5, lg: 10, xl: 20},
                            mt: { md: 8 },
                        }}
                    >                       
                            { section_id % 2 === 1 ?                                 
                                <Box>                                      
                                    <Typography variant="h4" sx={{fontSize: { md: "32px", xl: "36px", fontWeight: 600 }, textAlign: {md: 'left'}}}>
                                        {product}
                                    </Typography>                            
                                    <Typography variant="h5" fontWeight="400"  sx={{fontSize:  { md: "16px", xl: "24px" }, mt: { md: 5 }, color: "#CBCBCB", textAlign: {xs: 'center',md: 'left'}}}>
                                        {text3}
                                    </Typography>                            
                                    <Box sx={{ display: 'flex', justifyContent: {xs: 'center',md: 'left'} ,  mt: { xs: 4, md: 8 } }}>
                                        <Button variant="outlined" sx={{
                                            borderColor: "white",
                                            color: "white",
                                            textTransform: "none",
                                            width: "150px", // Set a fixed width for medium size (adjust if needed)
                                            borderRadius: 50,
                                            fontWeight: "600",
                                            }} href={productUrl} onClick={() => onClickLearnMoreButton(productName)}>
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                                :
                                <Box>                                      
                                    <img
                                        src={section_img} // Replace with the URL or path to your image
                                        style={{width: "90%"}}
                                        alt={productName + " screenshot"}
                                    />
                                </Box>
                            }
                        
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'block'},
                            width: { md: '50%' },
                            alignItems: 'stretch',
                            p:  { md: 4 },
                            pl: { md: 5, lg: 10, xl: 20}, 
                            pr: { md: 10, lg: 10, xl: 20},
                            mt: { md: 6 },                        
                        }}
                    >                          
                            { section_id % 2 === 1 ?                                                                 
                                <Box>                                     
                                    <img
                                        src={section_img} // Replace with the URL or path to your image
                                        style={{width: "90%"}}
                                        alt={productName + " screenshot"}
                                    />
                                </Box>                            
                                :
                                <Box> 
                                    <Typography variant="h4" sx={{fontSize: { xs: "18px", md: "32px", xl: "36px", fontWeight: 600 }, textAlign: {xs: 'center',md: 'left'}}}>
                                        {product}
                                    </Typography>                            
                                    <Typography variant="h5" fontWeight="400"  sx={{fontSize:  { xs: "14px", md: "16px", xl: "22px" }, mt: { xs: 3, md: 5 }, color: "#CBCBCB", textAlign: {xs: 'center',md: 'left'}}}>
                                        {text3}
                                    </Typography>                            
                                    <Box sx={{ display: 'flex', justifyContent: {xs: 'center',md: 'left'} ,  mt: { xs: 4, md: 8 } }}>
                                        <Button variant="outlined" sx={{
                                            borderColor: "white",
                                            color: "white",
                                            textTransform: "none",
                                            width: "150px", // Set a fixed width for medium size (adjust if needed)
                                            borderRadius: 50,
                                            fontWeight: "600",
                                            }} href={productUrl} onClick={() => onClickLearnMoreButton(product)}>
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                            }                        
                    </Box>
                </Stack>
                </>
 
            )
        }

        const deepkpi_section_props = {
            section_id: 0, 
            productName: "deepKPI",
            product: "deepKPI", //"Cut days down to seconds.", 
            text3: <><p>Build financial models with better data, faster.</p><p>No need to manually scrape SEC filings any more.&nbsp;&nbsp;We automatically discover and structure all the time series buried within the text and tables of Forms 10-K, 10-Q, and 8-K &mdash; resulting in the most comprehensive set of nuanced, company-specific KPIs available anywhere.</p><p>Audit any data point with one click.&nbsp;&nbsp;Browse on our website or pull directly into your models using our Excel add-in.</p></>, 
            section_img: HeroDeepKpiPng,
            productUrl: process.env.REACT_APP_DEEPKPI_URL
        };

        const deepanalysis_section_props = {
            section_id: 1, 
            productName: "deepAnalysis",
            product: <Box sx={{display: 'flex', flexDirection: 'row'}}>deepAnalysis <Box sx={{ml: "10px", mt: "-10px"}}><img src={AlphaIconSvg} height="40%" alt="alpha"/></Box></Box>, // "Audit every data point with one click.", 
            text3: <><p>Quickly ramp up on any public company.</p><p>Get complex questions answered fast by simply asking our AI.&nbsp;&nbsp;It'll search through billions of pages of SEC filings, as well as deepKPI time series, to produce the right answers, complete with citations.</p></>, 
            section_img: HeroDeepAnalysisWebp,
            productUrl: process.env.REACT_APP_DEEPANALYSIS_URL
        }

        const deepinsight_section_props = {
            section_id: 2, 
            productName: "deepInsight",
            product: "deepInsight",            
            text3: <><p>You can't outsource diligence, but this is the next best thing.</p><p>Institutional-quality research reports, featuring cogent, data-driven analyses, accompanied by detailed financial models &mdash; all driven by our deepKPI data, so you can drill right down to the source.</p><p>Use our research and models directly for your comps, or to jump-start your own custom analyses.</p></>, 
            section_img: HeroReportsWebp, 
            productUrl: process.env.REACT_APP_DEEPINSIGHT_URL
        }
;
        return (
            <Box
                sx={{
                    pl: {md: "15px", lg: "20px", xl:"50px"}, 
                    pr: {md: "15px", lg: "20px", xl: "50px"},
                }}>
                <ResponsiveSection {...deepkpi_section_props} />
                <ResponsiveSection {...deepanalysis_section_props} />
                <ResponsiveSection {...deepinsight_section_props} />
            </Box>
        )        
    }



    function ResponsiveFooter() {
        return (
            <>
            <Box sx={{display: {xs: 'none', md: 'block', lg: 'block'}, pl: 3, pr: 3, pt: 3, pb: 3}} border={0}>
                <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                    <img alt="" src={RevelataLogoSvg} height={33}/> 
                    {/* <Typography variant="h5" sx={{color: "var(--revelata-blue)"}}>revelata</Typography> */}
                </Stack>
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                    <Grid item xs={12} md={4} lg={4} display="flex">
                        <Typography sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Copyright &copy; 2024, Revelata, Inc. All rights reserved. </Typography>                
                    </Grid>                
                    <Grid item xs={12} md={4} lg={4} display="flex" justifyContent="center">
                        <Link href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b23e7803-82d9-4a25-8575-b1ac84488757" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Terms of Service</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                        <Link href="https://app.termly.io/policy-viewer/policy.html?policyUUID=d43ea6b9-44c7-4fae-b1c7-904c815b67c8" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Privacy Policy</Link>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} justifyContent="end">

                    </Grid>
                </Grid>
                </Stack>            
            </Box>

            <Box sx={{display: {xs: 'block', md: 'none', lg: 'none'}, pl: 3, pr: 3, pt: 3, pb: 3}}>
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" border={0}>
                    <img alt="" src={RevelataLogoSvg} height={33}/> 
                    {/* <Typography variant="h5" sx={{color: "var(--revelata-blue)"}}>revelata</Typography> */}
                </Stack>
                <Grid container direction="row" justifyContent="center" alignItems="stretch" border={0}>
                    <Grid item xs={12} md={4} lg={4} display="flex" alignItems="center" justifyContent="center">
                        <Typography sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Copyright &copy; 2024, Revelata, Inc. All rights reserved. </Typography>                
                    </Grid>                
                    <Grid item xs={12} md={4} lg={4} display="flex" justifyContent="center">
                        <Link href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b23e7803-82d9-4a25-8575-b1ac84488757" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Terms of Service</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link href="https://app.termly.io/policy-viewer/policy.html?policyUUID=d43ea6b9-44c7-4fae-b1c7-904c815b67c8" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Privacy Policy</Link>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} justifyContent="end">

                    </Grid>
                </Grid>
                </Stack>            
            </Box>
        </>
        )
    }



    return (
        <Container disableGutters maxWidth="false" >
            <ResponsiveLede/>
            <ResponsiveProductsSection/>
            <Divider/>
            <ResponsiveFooter/>
        </Container>
    )
}

export default Home;